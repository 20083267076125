<template>
    <!--nav-->
    <nav class="navbar navbar-default no-margin app-sidebar-nav">
        <div v-if="currentEnvironment" class="notification-block notification-block-top">
            <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary alert-dismissible">
                <div class="scroll-left">
                    <p>This is Test Environment !</p>
                </div>
            </div>
        </div>
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header fixed-brand">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" id="menu-toggle">
                <span class="glyphicon glyphicon-th-large" aria-hidden="true"></span>
            </button>
            <router-link class="navbar-brand" v-bind:to="{ name: 'Home'}">
                <img src="/static/images/logo-backend.png" alt="Velocity Vehicle Group">
            </router-link>
        </div>
        <!-- navbar-header-->
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul class="nav navbar-nav">
                <li class="active">
                    <button class="navbar-toggle collapse in" data-toggle="collapse" id="menu-toggle-2">
                        <img src="/static/images/menubar.png" alt="Menu" class="menubar">
                    </button>
                </li>
            </ul>
        </div>
        <!--notification-->
        <div v-show="roleId == 1" class="notification" style="padding-top: 15px;">
            <!--select class="selectpicker" data-width="fit">
              <option data-content='<span class="flag-icon flag-icon-us"></span> English'>English</option>
              <option  data-content='<span class="flag-icon flag-icon-mx"></span> Español'>Español</option>
          </select-->
          <a v-bind:class="{ active: enIsActiveLang }" href="#" title="English" v-on:click.prevent="languageChange('en')" style="font-size: 25px;color: #000000">EN</a>
          <a class="divider" style="font-size: 25px;color: #000000;">|</a>
          <a v-bind:class="{ active: esIsActiveLang }"  href="#" title="Spanish" v-on:click.prevent="languageChange('es')" style="font-size: 25px;color: #000000">ES</a>
        </div>
        <!--notification-->
        <!--userinfo-->
        <div class="user-info">


            <div class="user-info-container">

                <div class="dropdown">
                    <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                        <div class="dropdown-user">
                            <div class="username-letter">{{loggedin_user_img}}</div>
                            <div class="dropdown-user-right">
                                <div class="username">{{firstName.toUpperCase()}} {{lastName.toUpperCase()}}</div>
                                <br/>
                                <div class="usermailid">{{email}}</div>
                            </div>
                        </div>
                    </button>
                    <div class="dropdown-menu">
                        <!--<router-link class="dropdown-item" v-bind:to="{ name: 'ChangeProfile',params:{id:0}}">My Profile</router-link>-->
                        <a class="dropdown-item" href="#" v-on:click.prevent="logout()">{{ staticLanguageMessages.front_tool_tip_label_logout }}</a>
                    </div>
                </div>
            </div>
        </div>
        <!--userinfo-->
        <!-- bs-example-navbar-collapse-1 -->
    </nav>
    <!--nav-->
</template>
<script>
    import Vue from "vue";
    import EventBus from '../../event-bus';
    import SessioMixin from '../../../mixins/sessionmixin';
    var self;
    export default {
        mixins: [SessioMixin],
        name: 'App',
        data() {
            return {
                staticLanguageMessages: "",
                enIsActiveLang : true,
                esIsActiveLang : true,

                browser: 'firefox',
                userPermissions: [],
                roleId: 0,
                roleName: 0,
                system_year: '',
                galleryCount: 0,
                questionsCount: 0,
                answersCount: 0,
                commentsCount: 0,
                isShowLoggedinfields: true,
                loggedin_user_img: '',
                isLoggedIn: false,
                firstName: "",
                lastName: "",
                email: "",
                profile_image: "",
                loggedinUserId: ""
            }
        },
        components: {
        },
        beforeMount: function () {
            self = this;
            let userObj = localStorage.getItem('user_obj');
            if (userObj != null && userObj != "") {
                EventBus.$emit('is-login', true);
                self.getPermissions();
            }
        },
        mounted: function () {
            self.staticLanguageMessages = self.allStaticLabelMessages;

            let lang = localStorage.getItem('api_lang');
            if (lang != null && lang != "") {
              if(lang == 'es') {
                self.esIsActiveLang = true;
                self.enIsActiveLang = false;

              } else {
                self.esIsActiveLang = false;
                self.enIsActiveLang = true;
              }
            } else {
              self.esIsActiveLang = false;
              self.enIsActiveLang = true;
            }

            var browserData = navigator.userAgent;
            browserData = browserData.toLowerCase();
            if (browserData.indexOf('firefox') > -1) {
                self.browser = 'firefox';
            } else if ((browserData.indexOf('chrome') > -1)) {
                self.browser = 'chrome';
            } else if ((browserData.indexOf('safari') > -1)) {
                self.browser = 'safari';
            } else if (browserData.indexOf('windows') > -1) {
                self.browser = 'ie';
            } else if (browserData.indexOf('edge') > -1) {
                self.browser = 'ie';
            } else {
                self.browser = 'ie';
            }
            var userObj = localStorage.getItem("user_obj");
            if (userObj != null) {
                var userJson = JSON.parse(userObj);
                self.loginID = userJson.id;
                EventBus.$emit("logged-in", false, userJson["first_name"], userJson["last_name"], userJson["email"], localStorage.getItem("profile_image"));
            }
        },
        methods: {
            languageChange: function(lang) {
              localStorage.setItem('api_lang', lang);
              location.reload();
            },
        }
    }
    EventBus.$on('role', function (isLogin, id, name, system_year, userPermissions, galleryCount, questionsCount, answersCount, commentsCount) {
      // if(id <= 1) {
      //   $('#langOption').show();
      // } else {
      //   $('#langOption').hide();
      // }
        if (typeof (self) != "undefined") {
            self.isShowLoggedinfields = isLogin;
            self.roleId = id;
            self.userPermissions = userPermissions;
            self.roleName = name;
            console.log("name ==", name);
            self.system_year = system_year;
            self.galleryCount = galleryCount;
            self.questionsCount = questionsCount;
            self.answersCount = answersCount;
            self.commentsCount = commentsCount;
            if (self.roleId > 2/* && self.userPermissions.indexOf('access_social_media_calendar') <= -1*/) {
                self.$router.push("/");
            }
        }
    });
    EventBus.$on('logged-in', function (isLogin, fname, lname, email, profile_image) {
        if (typeof (self) != "undefined") {
            self.isShowLoggedinfields = isLogin;
            self.firstName = fname;
            self.lastName = lname;
            self.email = email;
            self.loggedin_user_img = (self.firstName.charAt(0) + self.lastName.charAt(0)).toUpperCase();
            self.profile_image = (profile_image != '') ? profile_image : '/static/images/default.jpg';
        }
    });


</script>

<style scoped>
.active{
  text-decoration: underline!important;
  color: #007bff!important;
}
</style>
