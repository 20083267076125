<template>
    <div id="main-footer">
        <section class="copyright">@ {{currentYear}}<!--2019-->   {{staticLanguageMessages.front_label_footer_msg}}</section>
        <cookie-law>
            <div slot="message">
                {{staticLanguageMessages.front_label_cookie}} <a class="policyLink" href="https://www.velocityvehiclegroup.com/privacy" target="_blank">{{staticLanguageMessages.front_label_learn_more}}</a>.
            </div>
        </cookie-law>
    </div>
</template>
<script>
    import index from "../../router";
    import EventBus from "../event-bus";
    import Vue from "vue";
    import SessioMixin from '../../mixins/sessionmixin';
    import CookieLaw from 'vue-cookie-law';
    import labelENMessages from '../../languages/label_en';
    import labelESMessages from '../../languages/label_es';
    import moment from 'moment';
    var self;
    export default {
        components: {
            CookieLaw,
        },
        /*import * as mykey from '/static/js/jquery.rotateSlider.js',*/
        name: "HomeFooter",
        mixins: [SessioMixin],
        data() {
            return {
                englishStaticLabelMessages: labelENMessages,
                spanishStaticLabelMessages: labelESMessages,
                staticLanguageMessages: "",
                isLoggedIn: false,
                isShowLoggedinfields: true,
                loginID: 0,
                userPermissions: [],
                roleId: 0,
                roleName: 0,
                currentYear: '2019',
            };
        },
        beforeMount: function () {
            self = this;
        },
        mounted: function () {
            // self.staticLanguageMessages = self.allStaticLabelMessages;
            let lang = localStorage.getItem('api_lang');
            if (lang != null && lang != "") {
              if(lang == 'es') {
                self.staticLanguageMessages = self.spanishStaticLabelMessages;
              } else {
                self.staticLanguageMessages = self.englishStaticLabelMessages;
              }
            } else {
              self.staticLanguageMessages = self.englishStaticLabelMessages;
            }
            var appYear = localStorage.getItem('app_year');
            if (typeof (appYear) != "undefined") {
                if (appYear != null) {
                    self.currentYear = appYear;
                } else {
                    self.currentYear = moment().format('YYYY');
                }
            } else {
                self.currentYear = moment().format('YYYY');
            }
        },
        methods: {

        }
    };
</script>
