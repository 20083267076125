<template>
    <div id="ui-1-component">
        <!--header-->
        <template v-for="(menu, index) in allMenuData">
            <div :id="menu.link_url" class="tabcontent">
                <template v-if="menu.ui_type == 'ui-1'">
                    <template v-if="menu.multyfile_allow == 1">
                        <div class="PageContent">
                            <div class="container">
                                <ul>
                                    <template v-for="(subMenu, subIndex) in menu.sub_menu">
                                        <template v-if="subMenu.is_active == 1">
                                            <template v-if="(subIndex % modBy) == 0">
                                                <template v-if='subMenu.documents.length == 1'>
                                                    <template v-for="(docs, docIndex) in subMenu.documents">
                                                        <li style='clear: both;'>
                                                            <a :href="docs.document" target="_blank">
                                                                <div class="ImageIcon">
                                                                    <img :src="subMenu.icon_image_path" :alt="subMenu.icon_label">
                                                                </div>
                                                                <template v-for="(word, wordIndex) in breakString(subMenu.icon_label ,20)">
                                                                    <div class="SectionName" :data-title="word">
                                                                        <h1>
                                                                            {{word}}
                                                                        </h1>
                                                                    </div>
                                                                </template>
                                                            </a>
                                                        </li>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="subMenu.documents.length > 0">
                                                        <li style='clear: both;'>
                                                            <a href="javascript:void(0);">
                                                                <div class="ImageIcon">
                                                                    <img :src="subMenu.icon_image_path" :alt="subMenu.icon_label">
                                                                </div>
                                                                <template v-for="(word, wordIndex) in breakString(subMenu.icon_label ,20)">
                                                                    <div class="SectionName" :data-title="word">
                                                                        <h1>
                                                                            {{word}}
                                                                        </h1>
                                                                    </div>
                                                                </template>
                                                                <div class="CustomSubTitle">
                                                                    <template v-for="(docs, docIndex) in subMenu.documents">
                                                                        <template v-if="subMenu.documents.length == (docIndex + 1)">
                                                                            &nbsp;   <a :href="docs.document" target="_blank">{{docs.document_label}}</a>
                                                                        </template>
                                                                        <template v-else>
                                                                            <a :href="docs.document" target="_blank">{{docs.document_label}}</a>   &nbsp; |
                                                                        </template>
                                                                    </template>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </template>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <template v-if='subMenu.documents.length == 1'>
                                                    <template v-for="(docs, docIndex) in subMenu.documents">
                                                        <li>
                                                            <a :href="docs.document" target="_blank">
                                                                <div class="ImageIcon">
                                                                    <img :src="subMenu.icon_image_path" :alt="subMenu.icon_label">
                                                                </div>
                                                                <template v-for="(word, wordIndex) in breakString(subMenu.icon_label ,20)">
                                                                    <div class="SectionName" :data-title="word">
                                                                        <h1>
                                                                            {{word}}
                                                                        </h1>
                                                                    </div>
                                                                </template>
                                                            </a>
                                                        </li>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="subMenu.documents.length > 0">
                                                        <li>
                                                            <a href="javascript:void(0);">
                                                                <div class="ImageIcon">
                                                                    <img :src="subMenu.icon_image_path" :alt="subMenu.icon_label">
                                                                </div>
                                                                <template v-for="(word, wordIndex) in breakString(subMenu.icon_label ,20)">
                                                                    <div class="SectionName" :data-title="word">
                                                                        <h1>
                                                                            {{word}}
                                                                        </h1>
                                                                    </div>
                                                                </template>
                                                                <div class="CustomSubTitle">
                                                                    <template v-for="(docs, docIndex) in subMenu.documents">
                                                                        <template v-if="subMenu.documents.length == (docIndex + 1)">
                                                                            &nbsp;   <a :href="docs.document" target="_blank">{{docs.document_label}}</a>
                                                                        </template>
                                                                        <template v-else>
                                                                            <a :href="docs.document" target="_blank">{{docs.document_label}}</a>   &nbsp; |
                                                                        </template>
                                                                    </template>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </template>
                                                </template>
                                                </li>
                                            </template>
                                        </template>

                                    </template>
                                </ul>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="PageContent">
                            <div class="container">
                                <ul>
                                    <template v-for="(subMenu, subIndex) in menu.sub_menu">
                                        <template v-if="subMenu.is_active == 1">
                                            <template v-for="(docs, docIndex) in subMenu.documents">
                                                <template v-if="(subIndex % modBy) == 0">
                                                    <li style='clear: both;'>
                                                        <a :href="docs.document" target="_blank">
                                                            <div class="ImageIcon">
                                                                <img :src="subMenu.icon_image_path" :alt="subMenu.icon_label">
                                                            </div>
                                                            <template v-for="(word, wordIndex) in breakString(subMenu.icon_label ,20)">
                                                                <div class="SectionName" :data-title="word">
                                                                    <h1>
                                                                        {{word}}
                                                                    </h1>
                                                                </div>
                                                            </template>
                                                        </a>
                                                    </li>
                                                </template>
                                                <template v-else>
                                                    <li>
                                                        <a :href="docs.document" target="_blank">
                                                            <div class="ImageIcon">
                                                                <img :src="subMenu.icon_image_path" :alt="subMenu.icon_label">
                                                            </div>
                                                            <template v-for="(word, wordIndex) in breakString(subMenu.icon_label ,20)">
                                                                <div class="SectionName" :data-title="word">
                                                                    <h1>
                                                                        {{word}}
                                                                    </h1>
                                                                </div>
                                                            </template>
                                                        </a>
                                                    </li>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </template>
                </template>
                <template v-else-if="menu.ui_type == 'ui-2'">
                    <div class="PageContent">
                        <div class="container">
                            <ul class="lileft">
                                <template v-for="(subChunkMenu, subChunkIndex) in menu.sub_menu">
                                    <template v-for="(subMenu, subIndex) in subChunkMenu">
                                        <template v-if="subMenu.is_active == 1">
                                            <template v-if="menu.name != 'OSHA'">
                                                <template v-if="subMenu.documents.length == 1">
                                                    <template v-for="(docs, docIndex) in subMenu.documents">
                                                        <template v-if="subIndex == 0">
                                                            <li>
                                                                <a :href="docs.document" target="_blank">
                                                                    <div class="ImageIcon">
                                                                        <img :src="subMenu.icon_image_path" :alt="subMenu.icon_label">
                                                                    </div>
                                                                    <template v-for="(word, wordIndex) in breakString(subMenu.icon_label ,20)">
                                                                        <div class="SectionName" :data-title="word">
                                                                            <h1>
                                                                                {{word}}
                                                                            </h1>
                                                                        </div>
                                                                    </template>
                                                                </a>
                                                            </li>
                                                        </template>
                                                        <template v-else-if="subIndex == 1">
                                                            <li>
                                                                <a :href="docs.document" target="_blank">
                                                                    <div class="ImageIcon">
                                                                        <img :src="subMenu.icon_image_path" :alt="subMenu.icon_label">
                                                                    </div>
                                                                    <template v-for="(word, wordIndex) in breakString(subMenu.icon_label ,20)">
                                                                        <div class="SectionName" :data-title="word">
                                                                            <h1>
                                                                                {{word}}
                                                                            </h1>
                                                                        </div>
                                                                    </template>
                                                                </a>
                                                            </li>
                                                        </template>
                                                        <template v-else>
                                                            <li>
                                                                <a :href="docs.document" target="_blank">
                                                                    <div class="ImageIcon">
                                                                        <img :src="subMenu.icon_image_path" :alt="subMenu.icon_label">
                                                                    </div>
                                                                    <template v-for="(word, wordIndex) in breakString(subMenu.icon_label ,20)">
                                                                        <div class="SectionName" :data-title="word">
                                                                            <h1>
                                                                                {{word}}
                                                                            </h1>
                                                                        </div>
                                                                    </template>
                                                                </a>
                                                            </li>
                                                        </template>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="subIndex == 0 && subMenu.documents.length > 0">
                                                        <li>
                                                            <a href="javascript:void(0);">
                                                                <div class="ImageIcon">
                                                                    <img :src="subMenu.icon_image_path" :alt="subMenu.icon_label">
                                                                </div>
                                                                <template v-for="(word, wordIndex) in breakString(subMenu.icon_label ,20)">
                                                                    <div class="SectionName" :data-title="word">
                                                                        <h1>
                                                                            {{word}}
                                                                        </h1>
                                                                    </div>
                                                                </template>
                                                            </a>
                                                            <div class="divhover">
                                                                <img src="/static/images/arrow.png" width="16" height="18">
                                                                <ul>
                                                                    <template v-for="(docs, docIndex) in subMenu.documents">
                                                                        <!--<template v-if="(docIndex + 1) > (subMenu.documents.length - 3)">
                                                                            <li class="CustomMarginBottom0"><a :href="docs.document" target="_blank">{{docs.document_label}}</a></li>
                                                                        </template>
                                                                        <template v-else>-->
                                                                        <li><a :href="docs.document" target="_blank">{{docs.document_label}}</a></li>
                                                                        <!--</template>-->
                                                                    </template>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </template>
                                                    <template v-else-if="subIndex == 1 && subMenu.documents.length > 0">
                                                        <li>
                                                            <a href="javascript:void(0);">
                                                                <div class="ImageIcon">
                                                                    <img :src="subMenu.icon_image_path" :alt="subMenu.icon_label">
                                                                </div>
                                                                <template v-for="(word, wordIndex) in breakString(subMenu.icon_label ,20)">
                                                                    <div class="SectionName" :data-title="word">
                                                                        <h1>
                                                                            {{word}}
                                                                        </h1>
                                                                    </div>
                                                                </template>
                                                            </a>
                                                            <div class="divhover vtrlhover menuvtrlhover">
                                                                <img src="/static/images/arrow.png">
                                                                <ul>
                                                                    <template v-for="(docs, docIndex) in subMenu.documents">
                                                                        <!--<template v-if="(docIndex + 1) > (subMenu.documents.length - 3)">
                                                                            <li class="CustomMarginBottom0"><a :href="docs.document" target="_blank">{{docs.document_label}}</a></li>
                                                                        </template>
                                                                        <template v-else>-->
                                                                        <li><a :href="docs.document" target="_blank">{{docs.document_label}}</a></li>
                                                                        <!--</template>-->
                                                                    </template>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </template>
                                                    <template v-else>
                                                        <template v-if="subMenu.documents.length > 0">
                                                            <li>
                                                                <a href="javascript:void(0);">
                                                                    <div class="ImageIcon">
                                                                        <img :src="subMenu.icon_image_path" :alt="subMenu.icon_label">
                                                                    </div>
                                                                    <template v-for="(word, wordIndex) in breakString(subMenu.icon_label ,20)">
                                                                        <div class="SectionName" :data-title="word">
                                                                            <h1>
                                                                                {{word}}
                                                                            </h1>
                                                                        </div>
                                                                    </template>
                                                                </a>
                                                                <div class="divhover vtrlhover vtthover">
                                                                    <img src="/static/images/arrow.png">
                                                                    <ul>
                                                                        <template v-for="(docs, docIndex) in subMenu.documents">
                                                                            <!--<template v-if="(docIndex + 1) > (subMenu.documents.length - 3)">
                                                                                <li class="CustomMarginBottom0"><a :href="docs.document" target="_blank">{{docs.document_label}}</a></li>
                                                                            </template>
                                                                            <template v-else>-->
                                                                            <li><a :href="docs.document" target="_blank">{{docs.document_label}}</a></li>
                                                                            <!--</template>-->
                                                                        </template>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        </template>
                                                    </template>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <template v-if="subMenu.location_name.length == 1">
                                                    <template v-for="(locs, locIndex) in subMenu.location_name">
                                                        <template v-if="subIndex == 0">
                                                            <li :key="'location_sub_index_0 ' + locIndex">
                                                                <router-link v-bind:to="{name: 'Violations', params:{comid: subMenu.compnay_id, facid: locs.id}}">
                                                                    <div class="ImageIcon">
                                                                        <img :src="subMenu.company_logo" :alt="subMenu.company_name">
                                                                    </div>
                                                                    <template v-for="(word, wordIndex) in breakString(subMenu.company_name, 20)">
                                                                        <div :key="'wordIndex ' + wordIndex" class="SectionName" :data-title="word">
                                                                            <h1>
                                                                                {{word}}
                                                                            </h1>
                                                                        </div>
                                                                    </template>
                                                                </router-link>
                                                            </li>
                                                        </template>
                                                        <template v-else-if="subIndex == 1">
                                                            <li :key="'location_sub_index_1 ' + locIndex">
                                                                <router-link v-bind:to="{name: 'Violations', params:{comid: subMenu.compnay_id, facid: locs.id}}">
                                                                    <div class="ImageIcon">
                                                                        <img :src="subMenu.company_logo" :alt="subMenu.company_name">
                                                                    </div>
                                                                    <template v-for="(word, wordIndex) in breakString(subMenu.company_name, 20)">
                                                                        <div :key="'wordIndex ' + wordIndex" class="SectionName" :data-title="word">
                                                                            <h1>
                                                                                {{word}}
                                                                            </h1>
                                                                        </div>
                                                                    </template>
                                                                </router-link>
                                                            </li>
                                                        </template>
                                                        <template v-else>
                                                            <li :key="'location_sub_index_all ' + locIndex">
                                                                <router-link v-bind:to="{name: 'Violations', params:{comid: subMenu.compnay_id, facid: locs.id}}">
                                                                    <div class="ImageIcon">
                                                                        <img :src="subMenu.company_logo" :alt="subMenu.company_name">
                                                                    </div>
                                                                    <template v-for="(word, wordIndex) in breakString(subMenu.company_name, 20)">
                                                                        <div :key="'wordIndex ' + wordIndex" class="SectionName" :data-title="word">
                                                                            <h1>
                                                                                {{word}}
                                                                            </h1>
                                                                        </div>
                                                                    </template>
                                                                </router-link>
                                                            </li>
                                                        </template>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="subIndex == 0">
                                                        <li :key="'osha ' + subIndex">
                                                            <a href="javascript:void(0);">
                                                                <div class="ImageIcon">
                                                                    <img :src="subMenu.company_logo" :alt="subMenu.company_name">
                                                                </div>
                                                                <template v-for="(word, wordIndex) in breakString(subMenu.company_name, 20)">
                                                                    <div :key="'location_word ' + wordIndex" class="SectionName" :data-title="word">
                                                                        <h1>
                                                                            {{word}}
                                                                        </h1>
                                                                    </div>
                                                                </template>
                                                            </a>
                                                            <div class="divhover">
                                                                <img src="/static/images/arrow.png" width="16" height="18">
                                                                <ul>
                                                                    <template v-for="(locs, locIndex) in subMenu.location_name">
                                                                        <li :key="'subMenu ' + locIndex">
                                                                            <router-link v-bind:to="{name: 'Violations', params:{comid: subMenu.compnay_id, facid: locs.id}}">
                                                                                {{locs.name}}
                                                                            </router-link>
                                                                        </li>
                                                                    </template>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </template>
                                                    <template v-else-if="subIndex == 1">
                                                        <li :key="'osha ' + subIndex">
                                                            <a href="javascript:void(0);">
                                                                <div class="ImageIcon">
                                                                    <img :src="subMenu.company_logo" :alt="subMenu.company_name">
                                                                </div>
                                                                <template v-for="(word, wordIndex) in breakString(subMenu.company_name, 20)">
                                                                    <div :key="'location_word ' + wordIndex" class="SectionName" :data-title="word">
                                                                        <h1>
                                                                            {{word}}
                                                                        </h1>
                                                                    </div>
                                                                </template>
                                                            </a>
                                                            <div class="divhover vtrlhover menuvtrlhover">
                                                                <img src="/static/images/arrow.png">
                                                                <ul>
                                                                    <template v-for="(locs, locIndex) in subMenu.location_name">
                                                                        <li :key="'subMenu ' + locIndex">
                                                                            <router-link v-bind:to="{name: 'Violations', params:{comid: subMenu.compnay_id, facid: locs.id}}">
                                                                                {{locs.name}}
                                                                            </router-link>
                                                                        </li>
                                                                    </template>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </template>
                                                    <template v-else>
                                                        <li :key="'osha ' + subIndex">
                                                            <a href="javascript:void(0);">
                                                                <div class="ImageIcon">
                                                                    <img :src="subMenu.company_logo" :alt="subMenu.company_name">
                                                                </div>
                                                                <template v-for="(word, wordIndex) in breakString(subMenu.company_name, 20)">
                                                                    <div :key="'location_word ' + wordIndex" class="SectionName" :data-title="word">
                                                                        <h1>
                                                                            {{word}}
                                                                        </h1>
                                                                    </div>
                                                                </template>
                                                            </a>
                                                            <div class="divhover vtrlhover vtthover">
                                                                <img src="/static/images/arrow.png">
                                                                <ul>
                                                                    <template v-for="(locs, locIndex) in subMenu.location_name">
                                                                        <li :key="'subMenu ' + locIndex">
                                                                            <router-link v-bind:to="{name: 'Violations', params:{comid: subMenu.compnay_id, facid: locs.id}}">
                                                                                {{locs.name}}
                                                                            </router-link>
                                                                        </li>
                                                                    </template>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </template>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </ul>
                        </div>
                    </div>
                    <!--<template v-if="menu.multyfile_allow == 1">

                    </template>
                    <template v-else>

                    </template>-->
                </template>
                <template v-else>
                    <div class="PageContent">
                        <div class="container">
                            <div class="programscontainer">
                                <ul class="CustomListing snip1135 fullwidthul">
                                    <template v-for="(subMenu, subIndex) in menu.sub_menu">
                                        <template v-if="subMenu.is_active == 1">
                                            <template v-if="(subIndex + 1) % 2 != 0">
                                                <template v-for="(docs, docIndex) in subMenu.documents">
                                                    <li>
                                                        <a :href="docs.document" :data-hover="subMenu.icon_label" target="_blank">
                                                            {{subMenu.icon_label}}
                                                        </a>
                                                    </li>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </ul>
                                <ul class="CustomListing snip1135 fullwidthul">
                                    <template v-for="(subMenu, subIndex) in menu.sub_menu">
                                        <template v-if="subMenu.is_active == 1">
                                            <template v-if="(subIndex + 1) % 2 == 0">
                                                <template v-for="(docs, docIndex) in subMenu.documents">
                                                    <li>
                                                        <a :href="docs.document" :data-hover="subMenu.icon_label" target="_blank">
                                                            {{subMenu.icon_label}}
                                                        </a>
                                                    </li>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!--<template v-if="menu.multyfile_allow == 1">

                    </template>
                    <template v-else>

                    </template>-->
                </template>
            </div>
        </template>
        <!--header-->
    </div>
</template>
<script>
    import index from "../../router";
    import EventBus from "../event-bus";
    import Vue from "vue";
    import SessioMixin from '../../mixins/sessionmixin';
    import {HTTP} from '../../http-common';
    var self;
    export default {
        /*import * as mykey from '/static/js/jquery.rotateSlider.js',*/
        name: "Home",
        mixins: [SessioMixin],
        props: {
            allMenuData: '',
        },
        data() {
            return {
                imageList: [],
                limit: 4,
                modBy: 3,
                offset: 0,
                pullCall: 1,
                is_mobile: false,
                isLoggedIn: false,
                isShowLoggedinfields: true,
                loginID: 0,
                userPermissions: [],
                roleId: 0,
                roleName: 0,
                system_year: '',
            };
        },
        beforeMount: function () {
            self = this;
        },
        mounted: function () {
            /*console.log("UI-1-Component");*/
            var windowWidth = $(window).width();
            if (windowWidth < 668) {
                self.modBy = 2;
            }
            $(window).resize(function () {
                var windowWidth = $(window).width();
                self.modBy = 3;
                if (windowWidth < 668) {
                    self.modBy = 2;
                }
            });
        },
        methods: {
            breakString(data, breakLength) {
                var windowWidth = $(window).width();
                if (windowWidth < 768) {
                    breakLength = 10;
                }
                var tempWord = "";
                var wordArr = [];
                if(data != null) {
                  var words = data.split(" ");
                  for (var i = 0; i < words.length; i++) {
                      if (((tempWord + words[i] + " ").trim()).length <= breakLength) {
                          tempWord = tempWord + words[i] + " ";
                      } else {
                          wordArr.push(tempWord.trim());
                          tempWord = "";
                          tempWord = tempWord + words[i] + " ";
                      }
                  }
                  if (tempWord != "") {
                      wordArr.push(tempWord.trim());
                      tempWord = "";
                  }

                }

                return wordArr;
            },
        }
    };
</script>
