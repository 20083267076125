<template>
    <!-- Sidebar -->
    <div id="sidebar-wrapper">
        <ul class="sidebar-nav nav-pills nav-stacked" id="menu">
            <li v-show="roleId == 1">
                <label class="tree-toggle nav-header"> <span class="fa-stack fa-lg pull-left"> <i class="fa fa-users fa-stack-1x "></i></span> {{staticLanguageMessages.label_sidebar_users_menu}} <i class="fa fa-angle-up" aria-hidden="true"></i></label>
                <ul class="nav nav-list tree">
                    <li><router-link v-bind:to="{name: 'ListUser'}">{{ staticLanguageMessages.label_sidebar_users_sub_menu }}</router-link></li>
                    <!--<li><router-link v-bind:to="{name: 'ListNotRegisteredUser'}">New Users</router-link></li>-->
                    <!--<li><router-link v-bind:to="{name: 'ListUser'}">Notifications</router-link></li>-->
                </ul>
            </li>
            <li v-show="roleId == 1">
                <label class="tree-toggle nav-header"> <span class="fa-stack fa-lg pull-left">
                <i class="fa fa-picture-o fa-stack-1x "></i></span> {{ staticLanguageMessages.label_sidebar_gallery_menu }}
                <span v-show="galleryCount > 0" class="color-c3202b">({{galleryCount}})</span><i class="fa fa-angle-down" aria-hidden="true"></i></label>
                <ul class="nav nav-list tree" style="display: none;">
                    <!--<li><router-link v-bind:to="{name: 'ListVideos'}">List Videos</router-link></li>-->
                    <li><router-link v-bind:to="{name: 'ListMedia'}">{{ staticLanguageMessages.label_sidebar_gallery_sub_menu_list_image }}</router-link></li>
                    <li><router-link v-bind:to="{name: 'ImageListForApproval'}">{{ staticLanguageMessages.label_sidebar_gallery_sub_menu_image_approval }} <span v-show="galleryCount > 0" class="color-c3202b">({{galleryCount}})</span></router-link></li>
                    <li><router-link v-bind:to="{name: 'AddMedia'}">{{ staticLanguageMessages.label_sidebar_gallery_sub_menu_add_image }}</router-link></li>
                    <!--<li><router-link v-bind:to="{name: 'ListCategory',params:{type:'gallery'}}">List Category</router-link></li>-->
                </ul>
            </li>
            <li v-show="roleId == 1 ">
                <router-link v-bind:to="{name: 'ListSafetyVideo'}"><span class="fa-stack fa-lg pull-left"><i class="fa fa-play fa-stack-1x "></i></span>{{ staticLanguageMessages.label_sidebar_safety_video_menu }}</router-link>
            </li>
            <li v-show="roleId <= 2 ">
                <router-link v-bind:to="{name: 'ListEvent'}"><span class="fa-stack fa-lg pull-left"><i class="fa fa-calendar fa-stack-1x "></i></span>{{ staticLanguageMessages.label_sidebar_calendar_event_menu }}</router-link>
            </li>
            <li v-show="roleId == 1 ">
                <router-link v-bind:to="{name: 'ListDocuments'}"><span class="fa-stack fa-lg pull-left"><i class="fa fa-list fa-stack-1x "></i></span>{{ staticLanguageMessages.label_sidebar_list_menu_data_menu }}</router-link>
            </li>
            <li v-show="roleId == 1">
                <label class="tree-toggle nav-header"> <span class="fa-stack fa-lg pull-left"> <i class="fa fa-comments-o fa-stack-1x "></i></span>{{ staticLanguageMessages.label_sidebar_forum_menu }} <span v-show="(questionsCount + answersCount + commentsCount) > 0" class="color-c3202b">({{questionsCount + answersCount + commentsCount}})</span><i class="fa fa-angle-down" aria-hidden="true"></i></label>
                <ul class="nav nav-list tree" style="display: none;">
                    <li><router-link v-bind:to="{name: 'ListForumQuestions'}">{{ staticLanguageMessages.label_sidebar_question_menu }} <span v-show="questionsCount > 0" class="color-c3202b">({{questionsCount}})</span></router-link></li>
                    <li><router-link v-bind:to="{name: 'ListForumAnswers'}">{{ staticLanguageMessages.label_sidebar_answer_menu }} <span v-show="answersCount > 0" class="color-c3202b">({{answersCount}})</span></router-link></li>
                    <li><router-link v-bind:to="{name: 'ListForumComments'}">{{ staticLanguageMessages.label_sidebar_comment_menu }} <span v-show="commentsCount > 0" class="color-c3202b">({{commentsCount}})</span></router-link></li>
                </ul>
            </li>
            <li v-show="roleId == 1 || showEnvironmentalMenu">
                <router-link v-bind:to="{name: 'ListEnvironmental'}"><span class="fa-stack fa-lg pull-left"><i class="fa fa-leaf fa-stack-1x "></i></span>{{ staticLanguageMessages.label_sidebar_environmental_menu }}</router-link>
            </li>
            <li v-show="roleId <= 1">
                <router-link v-bind:to="{name: 'ListInspections'}"><span class="fa-stack fa-lg pull-left"><i class="fa fa-search-plus fa-stack-1x "></i></span>{{ staticLanguageMessages.label_sidebar_inspection_menu }}</router-link>
            </li>
            <li v-show="roleId <= 1">
                <router-link v-bind:to="{name: 'ListFacility'}"><span class="fa-stack fa-lg pull-left"><i class="fa fa-map-marker fa-stack-1x "></i></span>{{ staticLanguageMessages.label_sidebar_facility_menu }}</router-link>
            </li>
            <li v-show="roleId <= 1">
                <router-link v-bind:to="{name: 'ListCompany'}"><span class="fa-stack fa-lg pull-left"><i class="fa fa-building-o fa-stack-1x "></i></span>{{ staticLanguageMessages.label_sidebar_company_menu }}</router-link>
            </li>
            <!--<li v-show="roleId == 1 ">
                <router-link v-bind:to="{name: 'ListIncidentReport'}"><span class="fa-stack fa-lg pull-left"><i class="fa fa-file-text-o fa-stack-1x "></i></span>Vehicle Incident Report</router-link>
            </li>-->

            <li v-show="roleId == 1">
                <label class="tree-toggle nav-header"> <span class="fa-stack fa-lg pull-left"> <i class="fa fa-users fa-stack-1x "></i></span> {{staticLanguageMessages.label_sidebar_cpr_training_header_menu}} <i class="fa fa-angle-up" aria-hidden="true"></i></label>
                <ul class="nav nav-list tree">
                    <li v-if="userPermissions.includes('can_add_cpr_training')"><router-link v-bind:to="{name: 'CPRTrainingEventsList'}">{{ staticLanguageMessages.label_sidebar_cpr_training_list_header_menu }}</router-link></li>
                    <li><router-link v-bind:to="{name: 'EnrolledInCprTrainingList'}">{{ staticLanguageMessages.label_sidebar_my_cpr_training_list_header_menu }}</router-link></li>
                    <!--<li><router-link v-bind:to="{name: 'ListNotRegisteredUser'}">New Users</router-link></li>-->
                    <!--<li><router-link v-bind:to="{name: 'ListUser'}">Notifications</router-link></li>-->
                </ul>
            </li>
        </ul>
    </div>
    <!-- Sidebar -->
</template>

<script>
    import Vue from 'vue';
    import EventBus from '../../event-bus';
    import SessioMixin from '../../../mixins/sessionmixin';
    var self;
    export default {
        name: 'Sidebar',
        props: [''],
        mixins: [SessioMixin],
        components: {

        },
        data() {
            return {
                staticLanguageMessages: "",
                showEnvironmentalMenu: false,
                isShowLoggedinfields: true,
                userPermissions: [],
                roleId: 0,
                roleName: 0,
                system_year: '',
                galleryCount: 0,
                questionsCount: 0,
                answersCount: 0,
                commentsCount: 0,
            }
        },
        beforeMount: function () {
            self = this;
        },
        mounted: function () {
            self.staticLanguageMessages = self.allStaticLabelMessages;
            $("#menu-toggle").click(function (e) {
                e.preventDefault();
                $(".app-sidebar-right").toggleClass("toggled");
            });
            $("#menu-toggle-2").click(function (e) {
                e.preventDefault();
                $(".app-sidebar-right").toggleClass("toggled-2");
                $('#menu ul').hide();
            });

            $('.tree-toggle').click(function () {
                /*$('ul.tree').hide();*/
                $(this).parent().children('ul.tree').toggle(200);
                if ($(this).children().last().attr('class').indexOf('fa-angle-down') != -1) {
                    $(this).children().last().addClass('fa-angle-up');
                    $(this).children().last().removeClass('fa-angle-down');
                } else {
                    $(this).children().last().removeClass('fa-angle-up');
                    $(this).children().last().addClass('fa-angle-down');
                }
            });

        },
        methods: {
        }
    }
    EventBus.$on('role', function (isLogin, id, name, system_year, userPermissions, galleryCount, questionsCount, answersCount, commentsCount) {
        if (typeof (self) != "undefined") {
            self.isShowLoggedinfields = isLogin;
            self.roleId = id;
            self.userPermissions = userPermissions;
            self.roleName = name;
            self.system_year = system_year;
            self.galleryCount = galleryCount;
            self.questionsCount = questionsCount;
            self.answersCount = answersCount;
            self.commentsCount = commentsCount;
        }
    });
    EventBus.$on('show-environmental-button', function (data) {
        if (typeof (self) != "undefined") {
            self.showEnvironmentalMenu = true;
        }
    });
</script>

