<template>
    <div id="app">
        <template v-if="$route.path.indexOf('/backend/') >= 0">
            <app-backend-header v-if="showHeader"></app-backend-header>
            <div id="wrapper" class="app-sidebar-right">
                <app-backend-sidebar v-if="showHeader"></app-backend-sidebar>
                <router-view></router-view>
            </div>
        </template>
        <template v-else-if="$route.path.indexOf('/login') >= 0">
            <router-view></router-view>
        </template>
        <template v-else>
            <template v-if="$route.path.indexOf('/violations/print') >= 0">
                <router-view></router-view>
            </template>
            <template v-else>
                <app-header></app-header>
                <router-view></router-view>
                <app-footer></app-footer>
            </template>
        </template>
    </div>
</template>

<script>
    import Header from '@/components/partials/Header';
    import Footer from '@/components/partials/Footer';
    import BackendHeader from '@/components/backend/partials/Header';
    import BackendSidebar from '@/components/backend/partials/Sidebar';

    import Vue from "vue";
    import linkify from "vue-linkify";
    Vue.directive('linkified', linkify);
    export default {
        name: 'App',
        data() {
            return {
                isBackend: true,
                showHeader: true,
            }
        },
        components: {
            'app-header': Header,
            'app-footer': Footer,
            'app-backend-header': BackendHeader,
            'app-backend-sidebar': BackendSidebar,
        },
    }
</script>