// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import router from './router';

import * as Sentry from "@sentry/vue";


import axios from 'axios';
// import 'core-js/es6/number';
// import 'core-js/shim';

/* import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue); */
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import 'vue-good-table/dist/vue-good-table.css';
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);
import linkify from 'vue-linkify';
Vue.directive('linkified', linkify);
Vue.config.productionTip = false



Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracing: true,
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    trackComponents: ["Header", "Navigation", "Footer"],
    hooks: ["create", "mount"],
  });

/*
 Vue filter to Capitalize initial letter.
 */
Vue.filter('capitalize', function (value) {
    if (!value) {
        return '';
    }
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter('truncate', function (source, size) {
    if (!source) {
        return '';
    }
    return source.length > size ? source.slice(0, size) + "..." : source;
});

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    // components: {App},
    render: h => h(App),
    template: '<App/>'
})
