import Vue from 'vue';
import Router from 'vue-router';
/* import Home from '@/components/Home';
import Login from '@/components/Login';
import ForgotPassword from '@/components/ForgotPassword';
import Register from '@/components/Register';
import ResetPassword from '@/components/ResetPassword'; */

/*import Clinics from '@/components/Clinics';*/
// import AddGalleryImages from '@/components/AddGalleryImages';

/*import Contractors from '@/components/Contractors';*/
/*import EmployeeIncident from '@/components/EmployeeIncident';*/
/*import Environment from '@/components/Environment';*/
/*import FacilityForms from '@/components/FacilityForms';*/
/*import FallProtection from '@/components/FallProtection';*/

// import Gallery from '@/components/Gallery';
/*import Kpa from '@/components/Kpa';*/
/*import NewHireOrientation from '@/components/NewHireOrientation';*/
/*import Procedures from '@/components/Procedures';*/
/*import Programs from '@/components/Programs';*/
/*import SafetyGlasses from '@/components/SafetyGlasses';*/
/*import VehicleIncident from '@/components/VehicleIncident';*/
/* import EventCalendar from '@/components/EventCalendar';
import SafetyVideoPlay from '@/components/SafetyVideoPlay';
import SafetyVideoList from '@/components/SafetyVideoList'; */


/*Back-end Start*/
/* import HelloWorld from '@/components/backend/HelloWorld';

import ListUser from '@/components/backend/user/ListUser';
import ChangeProfile from '@/components/backend/user/ChangeProfile';
import AddUpdateUsers from '@/components/backend/user/AddUpdateUsers';
import HRDragView from '@/components/backend/user/HRDragView';

import AddNotRegisteredUser from '@/components/backend/notregisteredusers/AddNotRegisteredUser';
import ListNotRegisteredUser from '@/components/backend/notregisteredusers/ListNotRegisteredUser';

import AddMedia from '@/components/backend/gallery/AddMedia';
import ListMedia from '@/components/backend/gallery/ListMedia';
import AddVideos from '@/components/backend/gallery/AddVideos';
import ListVideos from '@/components/backend/gallery/ListVideos';
import PlayVideos from '@/components/backend/gallery/PlayVideos';
import ImageListForApproval from '@/components/backend/gallery/ImageListForApproval';

import ListSafetyVideo from '@/components/backend/SafetyVideos/ListSafetyVideo';
import AddUpdateSafetyVideo from '@/components/backend/SafetyVideos/AddUpdateSafetyVideo';
import PlaySafetyVideos from '@/components/backend/SafetyVideos/PlaySafetyVideos';

import ListCategory from '@/components/backend/category/ListCategory';
import AddCategory from '@/components/backend/category/AddCategory';

import AddEvent from '@/components/backend/events/AddEvent';
import ListEvent from '@/components/backend/events/ListEvent';

import ListDocuments from '@/components/backend/fileUploadModule/ListDocuments';
import ListIncidentReport from '@/components/backend/fileUploadModule/ListIncidentReport';
import AddDocument from '@/components/backend/fileUploadModule/AddDocument';
import AddIncident from '@/components/backend/fileUploadModule/AddIncident';

import MenuIteamData from '@/components/MenuIteamData';

import Forum from '@/components/Forum';
import ForumDetails from '@/components/ForumDetails';
import ListForumQuestions from '@/components/backend/forum/ListForumQuestions';
import ListForumAnswers from '@/components/backend/forum/ListForumAnswers';
import ListForumComments from '@/components/backend/forum/ListForumComments';
import ListForumQuestionImages from '@/components/backend/forum/ListForumQuestionImages';
import ViewQuestionOfForumAnswer from '@/components/backend/forum/ViewQuestionOfForumAnswer';
import ViewQuestionAnswerOfForumComment from '@/components/backend/forum/ViewQuestionAnswerOfForumComment';

import AddEnvironmental from '@/components/AddEnvironmental';
import ListEnvironmental from '@/components/backend/Environmental/ListEnvironmental';
import ViewEnvironmental from '@/components/backend/Environmental/ViewEnvironmental';

import Violations from '@/components/Violations';
import ViolationsPrint from '@/components/ViolationsPrint';
import ListInspections from '@/components/backend/Inspection/ListInspections';
import AddInspection from '@/components/backend/Inspection/AddInspection';

import ListFacility from '@/components/backend/Facility/ListFacility';
import AddFacility from '@/components/backend/Facility/AddFacility';

import ListCompany from '@/components/backend/Company/ListCompany';
import AddCompany from '@/components/backend/Company/AddCompany';
import CPRTrainingEventCalendar from '@/components/cprtraining/TrainingEventCalendar';
import CPRTrainingEventsList from '@/components/cprtraining/TrainingEventList';
import AddUpdateCprTrainingForm from '@/components/cprtraining/AddUpdateCprTrainingForm'
import CprTrainingEventRegistrationForm from '@/components/cprtraining/CprTrainingEventRegistrationForm'
import EnrolledList from '@/components/cprtraining/EnrolledList'
import EnrolledInTrainingList from '@/components/cprtraining/EnrolledInTrainingList' */


/*Back-end End*/
Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            // component: Home,
            component: () => import('../components/Home.vue'),
        },
        {
            path: '/login',
            name: 'Login',
            // component: Login,
            component: () => import('../components/Login.vue'),
        },
        {
            path: '/register',
            name: 'Register',
            // component: Register,
            component: () => import('../components/Register.vue'),
        },
        {
            path: '/forgotpassword',
            name: 'ForgotPassword',
            // component: ForgotPassword,
            component: () => import('../components/ForgotPassword.vue'),
        },
        {
            path: '/resetpassword/:token',
            name: 'ResetPassword',
            // component: ResetPassword,
            component: () => import('../components/ResetPassword.vue'),
        },
        /*{
            path: '/clinics',
            name: 'Clinics',
            // component: Clinics,
            component: () => import('../components/Clinics.vue'),
         },*/
        /*{
            path: '/contractors',
            name: 'Contractors',
            // component: Contractors,
            component: () => import('../components/Contractors.vue'),
         },*/
        {
            path: '/gallery/add-image',
            name: 'AddGalleryImages',
            // component: AddGalleryImages,
            component: () => import('../components/AddGalleryImages.vue'),
        },
        {
            path: '/forum',
            name: 'Forum',
            // component: Forum,
            component: () => import('../components/Forum.vue'),
        },
        {
            path: '/violations/:comid/:facid',
            name: 'Violations',
            // component: Violations,
            component: () => import('../components/Violations.vue'),
        },
        {
            path: '/violations/print',
            name: 'ViolationsPrint',
            // component: ViolationsPrint,
            component: () => import('../components/ViolationsPrint.vue'),
        },
        {
            path: '/forum/:slug',
            name: 'ForumDetails',
            // component: ForumDetails,
            component: () => import('../components/ForumDetails.vue'),
        },
        /*{
            path: '/employee-incident',
            name: 'EmployeeIncident',
            //  component: EmployeeIncident,
            component: () => import('../components/EmployeeIncident.vue'),
         },*/
        /*{
            path: '/environment',
            name: 'Environment',
            //  component: Environment,
            component: () => import('../components/Environment.vue'),
         },*/
        /*{
            path: '/facility-forms',
            name: 'FacilityForms',
            //  component: FacilityForms,
            component: () => import('../components/FacilityForms.vue'),
            },*/
        /*{
            path: '/fall-protection',
            name: 'FallProtection',
            // component: FallProtection,
            component: () => import('../components/FallProtection.vue'),
        },*/
        {
            path: '/gallery',
            name: 'Gallery',
            // component: Gallery,
            component: () => import('../components/Gallery.vue'),
        },
        /*{
            path: '/kpa',
            name: 'Kpa',
            //  component: Kpa,
            component: () => import('../components/Kpa.vue'),
            },*/
        /*{
            path: '/new-hire-orientation',
            name: 'NewHireOrientation',
            //  component: NewHireOrientation,
            component: () => import('../components/NewHireOrientation.vue'),
            },*/
        /*{
            path: '/procedures',
            name: 'Procedures',
            //  component: Procedures,
            component: () => import('../components/Procedures.vue'),
            },*/
        /*{
            path: '/programs',
            name: 'Programs',
            //  component: Programs,
            component: () => import('../components/Programs.vue'),
            },*/
        /*{
            path: '/safety-glasses',
            name: 'SafetyGlasses',
            //  component: SafetyGlasses,
            component: () => import('../components/SafetyGlasses.vue'),
         },*/
        /*{
         path: '/vehicle-incident',
         name: 'VehicleIncident',
        //  component: VehicleIncident,
         component: () => import('../components/VehicleIncident.vue'),
         },*/
        {
            path: '/event-calendar',
            name: 'EventCalendar',
            // component: EventCalendar,
            component: () => import('../components/EventCalendar.vue'),
        },
        {
            path: '/safety-video/:id',
            name: 'SafetyVideoPlay',
            // component: SafetyVideoPlay,
            component: () => import('../components/SafetyVideoPlay.vue'),
        },
        {
            path: '/safety-video-list',
            name: 'SafetyVideoList',
            // component: SafetyVideoList,
            component: () => import('../components/SafetyVideoList.vue'),
        },
        {
            path: '/addenvironmental',
            name: 'AddEnvironmental',
            // component: AddEnvironmental,
            component: () => import('../components/AddEnvironmental.vue'),
        },
        {
            path: '/cpr-training-event-calendar', // We define this route before /:slug so can able to access this page directly instead on Menu Items
            name: 'CPRTrainingEventCalendar',
            // component: CPRTrainingEventCalendar,
            component: () => import('../components/cprtraining/TrainingEventCalendar.vue'),
        },
        {
            path: '/:slug',
            name: 'MenuIteamData',
            // component: MenuIteamData,
            component: () => import('../components/MenuIteamData.vue'),
        },

        /* Back-end start*/
        {
            path: '/backend/deshboard',
            name: 'HelloWorld',
            // component: HelloWorld,
            component: () => import('../components/backend/HelloWorld.vue'),
        },
        {
            path: '/backend/listusers',
            name: 'ListUser',
            // component: ListUser,
            component: () => import('../components/backend/user/ListUser.vue'),
        },
        {
            path: '/backend/changeprofile',
            name: 'ChangeProfile',
            // component: ChangeProfile,
            component: () => import('../components/backend/user/ChangeProfile.vue'),
        },
        {
            path: '/backend/updateusers/:id',
            name: 'AddUpdateUsers',
            // component: AddUpdateUsers,
            component: () => import('../components/backend/user/AddUpdateUsers.vue'),
        },
        {
            path: '/backend/hrdragview',
            name: 'HRDragView',
            // component: HRDragView,
            component: () => import('../components/backend/user/HRDragView.vue'),
        },
        {
            path: '/backend/listnotregistereduser',
            name: 'ListNotRegisteredUser',
            // component: ListNotRegisteredUser,
            component: () => import('../components/backend/notregisteredusers/ListNotRegisteredUser.vue'),
        },
        {
            path: '/backend/addnotregistereduser',
            name: 'AddNotRegisteredUser',
            // component: AddNotRegisteredUser,
            component: () => import('../components/backend/notregisteredusers/AddNotRegisteredUser.vue'),
        },
        {
            path: '/backend/updatenotregistereduser/:id',
            name: 'UpdateNotRegisteredUser',
            // component: AddNotRegisteredUser,
            component: () => import('../components/backend/notregisteredusers/AddNotRegisteredUser.vue'),
        },
        {
            path: '/backend/addimage',
            name: 'AddMedia',
            // component: AddMedia,
            component: () => import('../components/backend/gallery/AddMedia.vue'),
        },
        {
            path: '/backend/listmedia',
            name: 'ListMedia',
            // component: ListMedia,
            component: () => import('../components/backend/gallery/ListMedia.vue'),
        },
        {
            path: '/backend/addvideo',
            name: 'AddVideos',
            // component: AddVideos,
            component: () => import('../components/backend/gallery/AddVideos.vue'),
        },
        {
            path: '/backend/listvideos',
            name: 'ListVideos',
            // component: ListVideos,
            component: () => import('../components/backend/gallery/ListVideos.vue'),
        },
        {
            path: '/backend/playvideo/:id',
            name: 'PlayVideos',
            // component: PlayVideos,
            component: () => import('../components/backend/gallery/PlayVideos.vue'),
        },
        {
            path: '/backend/listsafetyvideo',
            name: 'ListSafetyVideo',
            // component: ListSafetyVideo,
            component: () => import('../components/backend/SafetyVideos/ListSafetyVideo.vue'),
        },
        {
            path: '/backend/addsafetyvideo',
            name: 'AddUpdateSafetyVideo',
            // component: AddUpdateSafetyVideo,
            component: () => import('../components/backend/SafetyVideos/AddUpdateSafetyVideo.vue'),
        },
        {
            path: '/backend/playsafetyvideo/:id',
            name: 'PlaySafetyVideos',
            // component: PlaySafetyVideos,
            component: () => import('../components/backend/SafetyVideos/PlaySafetyVideos.vue'),
        },
        {
            path: '/backend/imagelistforapproval',
            name: 'ImageListForApproval',
            // component: ImageListForApproval,
            component: () => import('../components/backend/gallery/ImageListForApproval.vue'),
        },
        {
            path: '/backend/listcategory/:type',
            name: 'ListCategory',
            // component: ListCategory,
            component: () => import('../components/backend/category/ListCategory.vue'),
        },
        {
            path: '/backend/addcategory/:type',
            name: 'AddCategory',
            // component: AddCategory,
            component: () => import('../components/backend/category/AddCategory.vue'),
        },
        {
            path: '/backend/updatecategory/:type/:id',
            name: 'UpdateCategory',
            // component: AddCategory,
            component: () => import('../components/backend/category/AddCategory.vue'),
        },
        {
            path: '/backend/addevent',
            name: 'AddEvent',
            // component: AddEvent,
            component: () => import('../components/backend/events/AddEvent.vue'),
        },
        {
            path: '/backend/updateevent/:id',
            name: 'UpdateEvent',
            // component: AddEvent,
            component: () => import('../components/backend/events/AddEvent.vue'),
        },
        {
            path: '/backend/listevent',
            name: 'ListEvent',
            // component: ListEvent,
            component: () => import('../components/backend/events/ListEvent.vue'),
        },
        {
            path: '/backend/listenvironmental',
            name: 'ListEnvironmental',
            // component: ListEnvironmental,
            component: () => import('../components/backend/Environmental/ListEnvironmental.vue'),
        },
        {
            path: '/backend/viewenvironmental/:id',
            name: 'ViewEnvironmental',
            // component: ViewEnvironmental,
            component: () => import('../components/backend/Environmental/ViewEnvironmental.vue'),
        },
        {
            path: '/backend/listinspections',
            name: 'ListInspections',
            // component: ListInspections,
            component: () => import('../components/backend/Inspection/ListInspections.vue'),
        },
        {
            path: '/backend/addinspection',
            name: 'AddInspection',
            // component: AddInspection,
            component: () => import('../components/backend/Inspection/AddInspection.vue'),
        },
        {
            path: '/backend/updateinspection/:id',
            name: 'UpdateInspection',
            // component: AddInspection,
            component: () => import('../components/backend/Inspection/AddInspection.vue'),
        },
        {
            path: '/backend/listfacility',
            name: 'ListFacility',
            // component: ListFacility,
            component: () => import('../components/backend/Facility/ListFacility.vue'),
        },
        {
            path: '/backend/addfacility',
            name: 'AddFacility',
            // component: AddFacility,
            component: () => import('../components/backend/Facility/AddFacility.vue'),
        },
        {
            path: '/backend/updatefacility/:id',
            name: 'UpdateFacility',
            // component: AddFacility,
            component: () => import('../components/backend/Facility/AddFacility.vue'),
        },
        {
            path: '/backend/listdocument',
            name: 'ListDocuments',
            // component: ListDocuments,
            component: () => import('../components/backend/fileUploadModule/ListDocuments.vue'),
        },
        {
            path: '/backend/adddocument',
            name: 'AddDocument',
            // component: AddDocument,
            component: () => import('../components/backend/fileUploadModule/AddDocument.vue'),
        },
        {
            path: '/backend/updatedocument/:id',
            name: 'UpdateDocument',
            // component: AddDocument,
            component: () => import('../components/backend/fileUploadModule/AddDocument.vue'),
        },
        {
            path: '/backend/listincidentreport',
            name: 'ListIncidentReport',
            // component: ListIncidentReport,
            component: () => import('../components/backend/fileUploadModule/ListIncidentReport.vue'),
        },
        {
            path: '/backend/addincident',
            name: 'AddIncident',
            // component: AddIncident,
            component: () => import('../components/backend/fileUploadModule/AddIncident.vue'),
        },
        {
            path: '/backend/updateincident/:id',
            name: 'UpdateIncident',
            // component: AddIncident,
            component: () => import('../components/backend/fileUploadModule/AddIncident.vue'),
        },
        {
            path: '/backend/forum-questions',
            name: 'ListForumQuestions',
            // component: ListForumQuestions,
            component: () => import('../components/backend/forum/ListForumQuestions.vue'),
        },
        {
            path: '/backend/forum-answers/:id?',
            name: 'ListForumAnswers',
            // component: ListForumAnswers,
            component: () => import('../components/backend/forum/ListForumAnswers.vue'),
        },
        {
            path: '/backend/forum-comments/:id?',
            name: 'ListForumComments',
            // component: ListForumComments,
            component: () => import('../components/backend/forum/ListForumComments.vue'),
        },
        {
            path: '/backend/forum-question-image/:id',
            name: 'ListForumQuestionImages',
            // component: ListForumQuestionImages,
            component: () => import('../components/backend/forum/ListForumQuestionImages.vue'),
        },
        {
            path: '/backend/forum-answer-details/:id/:questionId?',
            name: 'ViewQuestionOfForumAnswer',
            // component: ViewQuestionOfForumAnswer,
            component: () => import('../components/backend/forum/ViewQuestionOfForumAnswer.vue'),
        },
        {
            path: '/backend/forum-comment-details/:id/:answerId?',
            name: 'ViewQuestionAnswerOfForumComment',
            // component: ViewQuestionAnswerOfForumComment,
            component: () => import('../components/backend/forum/ViewQuestionAnswerOfForumComment.vue'),
        },
        {
            path: '/backend/listcompany',
            name: 'ListCompany',
            // component: ListCompany,
            component: () => import('../components/backend/Company/ListCompany.vue'),
        },
        {
            path: '/backend/addcompany',
            name: 'AddCompany',
            // component: AddCompany,
            component: () => import('../components/backend/Company/AddCompany.vue'),
        },
        {
            path: '/backend/updatecompany/:id',
            name: 'UpdateCompany',
            // component: AddCompany,
            component: () => import('../components/backend/Company/AddCompany.vue'),
        },
        {
            path: '/backend/cprtraining/list',
            name: 'CPRTrainingEventsList',
            // component: CPRTrainingEventsLis,
            component: () => import('../components/cprtraining/TrainingEventList.vue'),
        },
        {
            path: '/backend/cprtraining/form',
            name: 'AddUpdateCprTrainingForm',
            // component: AddUpdateCprTrainingForm,
            component: () => import('../components/cprtraining/AddUpdateCprTrainingForm.vue'),
        },
        {
            path: '/backend/updatecprtraining/:id',
            name: 'UpdateCprTrainingForm',
            // component: AddUpdateCprTrainingForm,
            component: () => import('../components/cprtraining/AddUpdateCprTrainingForm.vue'),
        },
        {
            path: '/backend/cprtrainingregistration/:id',
            name: 'CprTrainingEventRegistrationForm',
            // component: CprTrainingEventRegistrationForm,
            component: () => import('../components/cprtraining/CprTrainingEventRegistrationForm.vue'),
        },
        {
            path: '/backend/cprtraining/:id/enrolled',
            name: 'CprTrainingEnrolledList',
            // component: EnrolledList,
            component: () => import('../components/cprtraining/EnrolledList.vue'),
        },
        {
            path: '/backend/cprtraining/enrolledment',
            name: 'EnrolledInCprTrainingList',
            // component: EnrolledInTrainingList,
            component: () => import('../components/cprtraining/EnrolledInTrainingList.vue'),
        },
    ]
})
